<template>
    <div class="content-area__body" >
        <section class="counter-box section-gap-30">
            <div class="content-area__unverified">
                <h1 class="page-title text-center section-gap">{{ $t("Please check your email for a verification link")}}</h1>
                <p class="text-center"> {{ $t("Before proceeding, please check your email for a verification link.")}} <br> {{ $t("If you did not receive the email")}}</p>

                <div class="row">
                    <div class="col-6 offset-3">
                        <div class="alert alert-success text-center text-break mt-4" v-if="responseText.length > 0" role="alert">
                            {{ responseText }}
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center mt-4">
                    <submit-button type="danger-button" :loading="loading" :click="resendEmail">{{ $t("click here to request another")}}</submit-button>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6 offset-md-3">
                        <img src="/img/Repeat Grid 3.png" alt="" class="img-fluid w-100">
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { request } from "../../app/api/Client";
    import {mapActions} from "vuex";
    import {USER_LOGGED_IN} from "../../constants/action-type";

    export default {
        data() {
            return {
                responseText : '',
                form : {

                },
                loading : false
            }
        },

        methods: {
            ...mapActions([USER_LOGGED_IN]),
            resendEmail() {
                this.loading = true;
                request
                .post('/verification/email/resend', this.form)
                .then(({data: {data, message}}) => {

                    this[USER_LOGGED_IN](data);
                    this.responseText = message;
                    this.$toast.success(message);

                }).catch(error => {
                    return this.$toast.success(error.response.message);
                }).finally(() => {
                    this.loading = false;

                });
            },
        },
    }
</script>
